@media screen and (max-width: 768px) {
  /* #home__section-1 start */
  #home__section-1 {
    margin-bottom: 25px;
  }

  #home__section-1 .title {
    font-size: 54px;
    line-height: 54px;
  }

  #home__section-1 .text {
    margin-top: 15px;
  }

  #home__section-1 .button-box {
    margin-top: 25px;
    margin-bottom: 20px;
  }

  #home__section-1 .text-2 {
    max-width: 75%;
    line-height: 20px;
  }
  /* #home__section-1 end */

  /* #home__section-2 start */
  #home__section-2 {
    margin-bottom: 50px;
  }

  #home__section-2 .title {
    font-size: 32px;
    line-height: 36px;
  }

  #home__section-2 .cards {
    flex-direction: column;
    row-gap: 30px;
  }

  #home__section-2 #cardsWeb {
    display: none;
  }

  #home__section-2 #cardsMobile {
    display: block;
  }

  #home__section-2 #cardsMobile .card {
    height: 460px;
  }

  .carousel-indicators [data-bs-target] {
    background-color: #fe5000;
    border-radius: 50%;
    width: 7px;
    height: 7px;
  }

  .carousel-indicators {
    margin-bottom: -30px;
  }

  #home__section-2 .cards .card {
    padding: 40px;
    min-height: 365px;
    background-size: 35%;
    width: 100%;
  }

  #home__section-2 .cards .card .title {
    font-size: 24px;
    line-height: 24px;
  }

  #home__section-2 .cards .card .text {
    max-width: none;
  }

  #home__section-2 .cards .card img {
    width: 325px;
    position: absolute;
    right: -20px;
    bottom: -20px;
    border-bottom-right-radius: 40px;
    overflow: hidden;
  }
  /* #home__section-2 end */

  /* #home__section-4 start */
  #home__section-4 {
    margin-bottom: 60px;
  }

  #home__section-4 > .title {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 35px;
  }

  #home__section-4 .cards {
    flex-direction: column;
    row-gap: 30px;
  }

  #home__section-4 .cards .card {
    width: 100%;
  }

  /* #home__section-4 end */

  /* #home__section-5 start */
  #home__section-5 .right-content > .title {
    font-size: 35px;
    line-height: 37px;
    margin-bottom: 35px;
  }

  #home__section-5 .right-content .cards {
    row-gap: 35px;
  }

  #home__section-5 .right-content .cards .card {
    max-width: none;
  }
  /* #home__section-5 end */

  /* #home__section-6 start */
  #home__section-6 {
    margin-bottom: 50px;
  }

  #home__section-6 .large-card {
    flex-direction: column;
    row-gap: 0;
  }

  #home__section-6 .large-card .left-content {
    padding: 40px 40px 0 40px;
    width: 100%;
  }

  #home__section-6 .large-card .left-content .text {
    max-width: none;
  }

  #home__section-6 .large-card .right-img {
    margin: 0;
    border-bottom-left-radius: 40px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  #home__section-6 .large-card .right-img img {
    height: 300px;
    width: auto;
    margin-left: -20px;
  }
  /* #home__section-6 end */

  /* #home__section-7 start */
  #home__section-7 #issue {
    font-size: 30px;
    line-height: 30px;
    width: 331px;
  }
  #home__section-7 #button-box .cta-button {
    margin-top: 20px;
    padding: 15px 35px;
  }
  /* #home__section-7 end */

  /* modal start */
  #modal .modal-content {
    width: 320px;
    height: 470px;
  }

  #modal-header {
    margin: 20px 10px 0 10px;
  }

  /* modal end */

  /* #home__faq-section start */
  #home__faq-section .title {
    font-size: 34px;
    line-height: 34px;
    margin-bottom: 78px;
  }
  /* #home__faq-section end */
}

@media screen and (max-width: 550px) {
  #home__section-6 .large-card .right-img img {
    height: 250px;
  }
}

@media screen and (max-width: 400px) {
  #home__section-6 .large-card .right-img img {
    height: 200px;
  }
}
